// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bo__test-mode-container {
  position: absolute;
  bottom: 5.9rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.bo__test-mode-container .ant-typography {
  font-size: 1rem;
  color: #3b4858;
}
`, "",{"version":3,"sources":["webpack://./src/components/TestMode/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,WAAA;EACA,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,qBAAA;AACF;AARA;EASI,eAAA;EACA,cAAA;AAEJ","sourcesContent":[".bo__test-mode-container {\n  position: absolute;\n  bottom: 5.9rem;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding-left: 1.5rem;\n  padding-right: 1.5rem;\n  .ant-typography {\n    font-size: 1rem;\n    color: #3b4858;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
