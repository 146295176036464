// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bo__page-layout {
  display: flex !important;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.bo__page-layout .bo__anchor-menu {
  background: transparent;
  display: flex;
  justify-content: flex-end;
}
.bo__page-layout .bo-side-bar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 16.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.bo__page-layout .bo-site-layout {
  position: fixed;
  width: calc(100vw - 16.25rem);
  margin-left: 16.25rem;
  overflow: hidden;
}
.bo__page-layout .bo-site-layout .header {
  height: 4.875rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #f2f6fa;
  border-bottom: solid 1px #cddae8;
}
.bo__page-layout .bo-site-layout .content {
  background: #f2f6fa;
  text-align: left;
  padding: 2rem 3rem;
  height: calc(100vh - 4.875rem);
  overflow-y: auto;
  overflow-x: hidden;
}
.bo__page-layout .bo-empty-content-icon {
  width: 300px;
  overflow: hidden;
  margin: 100px auto;
}
.bo-header-container {
  display: flex;
}
.bo-header-container > div:first-child {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/PageLayout/style.less"],"names":[],"mappings":"AAGA;EACE,wBAAA;EACA,aAAA;EACA,YAAA;EACA,gBAAA;AAFF;AAFA;EAOI,uBAAA;EACA,aAAA;EACA,yBAAA;AAFJ;AAPA;EAYI,eAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,eAAA;EACA,yCAAA;AAFJ;AAfA;EAqBI,eAAA;EACA,6BAAA;EACA,qBAAA;EACA,gBAAA;AAHJ;AArBA;EA2BM,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;AAHN;AA7BA;EAoCM,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;AAJN;AArCA;EA8CI,YAAA;EACA,gBAAA;EACA,kBAAA;AANJ;AAUA;EACE,aAAA;AARF;AAOA;EAGI,OAAA;AAPJ","sourcesContent":["@sidebarWidth: 16.25rem;\n@topBarHeight: 4.875rem;\n\n.bo__page-layout {\n  display: flex !important;\n  height: 100vh;\n  width: 100vw;\n  overflow: hidden;\n\n  .bo__anchor-menu {\n    background: transparent;\n    display: flex;\n    justify-content: flex-end;\n  }\n  .bo-side-bar {\n    position: fixed;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    width: @sidebarWidth;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n  }\n\n  .bo-site-layout {\n    position: fixed;\n    width: calc(100vw - ~'@{sidebarWidth}');\n    margin-left: @sidebarWidth;\n    overflow: hidden;\n\n    .header {\n      height: @topBarHeight;\n      display: flex;\n      justify-content: flex-end;\n      align-items: center;\n      background: #f2f6fa;\n      border-bottom: solid 1px #cddae8;\n    }\n\n    .content {\n      background: #f2f6fa;\n      text-align: left;\n      padding: 2rem 3rem;\n      height: calc(100vh - ~'@{topBarHeight}');\n      overflow-y: auto;\n      overflow-x: hidden;\n    }\n  }\n\n  .bo-empty-content-icon {\n    width: 300px;\n    overflow: hidden;\n    margin: 100px auto;\n  }\n}\n\n.bo-header-container {\n  display: flex;\n  > div:first-child {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
