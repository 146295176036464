// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bo__user-dropdown-menu {
  position: absolute;
  bottom: 1rem;
  height: 3rem;
  width: 13.25rem;
  margin: 0rem 1.5rem;
  display: flex;
}
.bo__user-dropdown-menu .ant-avatar-string {
  font-size: 14px;
  padding-top: 0.5rem;
}
.bo__user-dropdown-menu .down-arrow {
  position: absolute;
  top: 42%;
  right: 1rem;
}
.bo__user-dropdown-menu .ant-dropdown-link {
  font-size: 14px;
}
.bo__user-dropdown-menu .dropdown-container {
  width: 10.25rem;
}
.bo__user-dropdown-menu .avatar {
  width: 3rem;
  height: 100%;
  color: #ffffff;
  background-color: #3b4858;
  border-radius: 6px 0px 0px 6px;
}
.bo__user-dropdown-menu .button-dropdown {
  background-color: #f2f6fa;
  border-radius: 0px 6px 6px 0px;
  height: 100%;
  width: 100%;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/UserDropdown/style.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;AACF;AAPA;EASI,eAAA;EACA,mBAAA;AACJ;AAXA;EAcI,kBAAA;EACA,QAAA;EACA,WAAA;AAAJ;AAhBA;EAoBI,eAAA;AADJ;AAnBA;EAwBI,eAAA;AAFJ;AAtBA;EA2BI,WAAA;EACA,YAAA;EACA,cAAA;EACA,yBAAA;EACA,8BAAA;AAFJ;AA7BA;EAkCI,yBAAA;EACA,8BAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AAFJ","sourcesContent":[".bo__user-dropdown-menu {\n  position: absolute;\n  bottom: 1rem;\n  height: 3rem;\n  width: 13.25rem;\n  margin: 0rem 1.5rem;\n  display: flex;\n\n  .ant-avatar-string {\n    font-size: 14px;\n    padding-top: 0.5rem;\n  }\n\n  .down-arrow {\n    position: absolute;\n    top: 42%;\n    right: 1rem;\n  }\n\n  .ant-dropdown-link {\n    font-size: 14px;\n  }\n\n  .dropdown-container {\n    width: 10.25rem;\n  }\n  .avatar {\n    width: 3rem;\n    height: 100%;\n    color: #ffffff;\n    background-color: #3b4858;\n    border-radius: 6px 0px 0px 6px;\n  }\n  .button-dropdown {\n    background-color: #f2f6fa;\n    border-radius: 0px 6px 6px 0px;\n    height: 100%;\n    width: 100%;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
